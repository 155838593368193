var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('dropPackage.pageTitle'),"breadcrumb":[
                { text: _vm.$t('home'), href: '/' },
                {
                    text: _vm.$t('dropPackage.pageTitle'),
                    active: true
                }
            ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('a-range-picker',{staticStyle:{"width":"200px"},attrs:{"allowClear":false,"format":"DD-MM-YYYY","valueFormat":"DD-MM-YYYY"},on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.dateRequest),callback:function ($$v) {_vm.dateRequest=$$v},expression:"dateRequest"}}),_c('a-tooltip',{attrs:{"placement":"topLeft","title":_vm.$t('dropPackage.search')}},[_c('a-input',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('dropPackage.search')),expression:"$t('dropPackage.search')"}],staticClass:"inline-block",staticStyle:{"width":"250px"},attrs:{"placeholder":_vm.$t('dropPackage.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]),_c('div',{staticClass:"tw-overflow-x-scroll"},[_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading,"expandable":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var record = ref.record;
return [_c('td',[_vm._v(_vm._s(record.dropped_time))]),_c('td',[_vm._v(_vm._s(record.agency_code))]),_c('td',[_vm._v(_vm._s(record.agency_name))]),_c('td',[_vm._v(_vm._s(record.driver_code))]),_c('td',[_vm._v(_vm._s(record.driver_name))]),_c('td',{staticClass:"tw-text-center"},[_vm._v(_vm._s(record.sex))]),_c('td',{staticClass:"tw-text-center"},[_vm._v(_vm._s(record.packages))]),_c('td',{staticClass:"tw-text-center"},[_vm._v(" "+_vm._s(record.received_by_name)+" ")]),_c('td',{staticClass:"tw-text-center tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.received_time)+" ")]),_c('td',{staticClass:"tw-text-center"},[(!record.received_by_name)?_c('a-popconfirm',{attrs:{"placement":"leftTop","title":_vm.$t('are_you_sure_to_receive'),"ok-text":_vm.$t('yes'),"cancel-text":_vm.$t('no')},on:{"confirm":function($event){return _vm.onSave(record)}}},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-clipboard-check"})])]):_vm._e()],1)]}},{key:"expand",fn:function(ref){
            var record = ref.record;
return [_c('table',{staticClass:"tw-w-full table"},[_c('thead',[_c('tr',[_c('th',{staticClass:"tw-w-3 tw-text-center"},[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("dropPackage.voucherNo")))]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("dropPackage.deliveryNumber"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("dropPackage.bookingNumber"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("dropPackage.receiverPhone"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("dropPackage.receiverLocation"))+" ")])])]),_c('tbody',_vm._l((record.details),function(d,index){return _c('tr',{key:index},[_c('td',{staticClass:"tw-text-center"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',[_vm._v(_vm._s(d.voucher_no))]),_c('td',[_vm._v(_vm._s(d.delivery_number))]),_c('td',[_vm._v(_vm._s(d.booking_number))]),_c('td',[_vm._v(_vm._s(d.receiver_phone))]),_c('td',[_vm._v(_vm._s(d.receiver_location))])])}),0)])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end tw-p-4"},[_c('ts-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }